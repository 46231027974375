import { createAsyncThunk } from '@reduxjs/toolkit';
import { EApiStatuses } from '../../constants';
import { ELocalStoreKeys } from '../../models/consts';
import {
  ICreateOrganizationUser,
  IEditUser,
  IError,
  ILogin, ILoginProvider,
  IMarketingData,
  ISetPaymentMethod, IUpdOrganizationUser,
  UserEmailProps
} from '../../models/inner-models';
import {IUser} from '../../models/models';
import { EUser } from '../constants';
import instance from '../middlewares/api';
import { clearBackupsState } from '../reducers/backups';
import { clearPlatformState } from '../reducers/platform';
import {loginCognito, loginGoogleCognito, logoutCognito, updateUserInfo} from './auth';
import { getAllSubscriptions } from './platformActions/allPlatformsActions';
import {AxiosError} from "axios";

const login = createAsyncThunk(
  EUser.loginUser,
  async (loginInfo: ILogin, thunkAPI) => {
    try {
      const idToken = await thunkAPI.dispatch(loginCognito(loginInfo)).unwrap();
      if (idToken) {
        localStorage.setItem(ELocalStoreKeys.TOKEN, idToken);
        await thunkAPI.dispatch(userAction('login'))
        await thunkAPI.dispatch(getUser())
      }
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const loginGoogle = createAsyncThunk(
  EUser.loginUserG,
  async (payload: ILoginProvider, thunkAPI) => {
    try {
      const idToken = payload.isOkta ? payload.token : await thunkAPI.dispatch(loginGoogleCognito(payload.token)).unwrap();

      if (idToken) {
        localStorage.setItem(ELocalStoreKeys.TOKEN, idToken);
        await thunkAPI.dispatch(userAction('login'))
        await thunkAPI.dispatch(getUser())
      }
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const logout = createAsyncThunk(
  EUser.logout,
  async (isClick: boolean, thunkAPI) => {
    try {
      localStorage.setItem('tokenExpire', '1')

      const result = await thunkAPI.dispatch(logoutCognito()).unwrap();
      if (result && 'error' in result) {
        return thunkAPI.rejectWithValue({ error: result.error });
      }
      await thunkAPI.dispatch(userAction('logout'))
      const lastPage = localStorage.getItem('lastPage')
      const notFirstTime = !localStorage['firstTime']
      localStorage.clear();
      if (lastPage && isClick && notFirstTime) {
        localStorage.setItem('lastPage', lastPage)
      }

      thunkAPI.dispatch(clearBackupsState());
      thunkAPI.dispatch(clearPlatformState());
      return true;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const userAction = createAsyncThunk(
  EUser.actionUser,
  async (action: string, thunkAPI) => {
    try {
      const data = await instance.post(
        'user/user-action',
        {
          action: action
        }
      );
      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const getUser = createAsyncThunk(
  EUser.getCurrentUser,
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem(ELocalStoreKeys.TOKEN);
      if (token) {
        const data = await Promise.all([ instance.get('user/get-user-start-info'), thunkAPI.dispatch(getAllSubscriptions())])
          .then(res => {
            return res[0]
          })
        if (!data?.data || data.data.status !== EApiStatuses.SUCCESS) {
          await thunkAPI.dispatch(setUserLoading(false))
          await thunkAPI.dispatch(logout(false));
          return thunkAPI.rejectWithValue({ error: data.data.message })
        } else {
          // await thunkAPI.dispatch(getAllSubscriptions());
          return data.data.result;
        }
      }
    } catch (err) {
      console.log(err)
      const error = err as IError;
      if ((err as AxiosError)?.response?.status === 403) {
        await thunkAPI.dispatch(setUserTestData())
      } else {
        await thunkAPI.dispatch(setUserLoading(false))
        await thunkAPI.dispatch(logout(false));
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
    return null;
  },
);

const editUser = createAsyncThunk(
  EUser.editUser,
  async (userInfo: IEditUser, thunkAPI) => {
    try {
      const result = (await thunkAPI.dispatch(updateUserInfo(userInfo))).payload as IUser | { error: string };
      if (result && 'error' in result) {
        return thunkAPI.rejectWithValue({ error: result.error });
      }
      return result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const setUserLoading = createAsyncThunk(
  EUser.stopLoading,
   (load: boolean) => {
    return load
  },
);

const setUserPro = createAsyncThunk(
  EUser.proUser,
   (pro: boolean) => {
    return pro
  },
);

const setUserTestData = createAsyncThunk(
  EUser.testLoading,
   () => {
    return false
  },
);

const updateUserAvatar = createAsyncThunk(
  EUser.updateAvatar,
  async (avatarImg: string, thunkAPI) => {
    try {
      const data = await instance.post('user/add-profile-image', { avatar: avatarImg });
      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result.url;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const updateUserData = createAsyncThunk(
  EUser.updateUser,
  async (userData: IEditUser, thunkAPI) => {
    try {
      const data = await instance.post(
        'user/update',
        {
          family_name: userData.lastName,
          name: userData.firstName,
        }
      );
      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const cancelAllUsersSubscriptions = createAsyncThunk(
  EUser.cancelAllSubs,
  async (_, thunkAPI) => {
    try {
      const data = await instance.get('payments/stripe-cancel-all-subscriptions', {});
      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result.url;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const unsubscribeMailProfile = createAsyncThunk(
  EUser.unsubscribeProfile,
  async (isSubscribedToMail: boolean, thunkAPI) => {
    try {
      const data = await instance.put('user/subscribe-mail-sending', {isSubscribedToMail});

      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        {...data.data, isSubscribedToMail};
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const putUserEmailPreferences = createAsyncThunk(
  EUser.putUserEmail,
  async (payload: UserEmailProps , thunkAPI) => {
    try {
      const data = await instance.put('user/email-preferences', {email_preferences: payload});

      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        {...data.data, payload};
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const getUserEmailPreferences = createAsyncThunk(
  EUser.getUserEmail,
  async (_, thunkAPI) => {
    try {
      const data = await instance.get('user/email-preferences');

      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result || {};
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const getUserProfileName = createAsyncThunk(
  EUser.profileUser,
  async (_, thunkAPI) => {
    try {
      const data = await instance.get('user/get-user-platform-names');

      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.platformNames;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const cancelOneSubscription = createAsyncThunk(
  EUser.cancelOneSub,
  async (platform: string, thunkAPI) => {
    try {
      const data = await instance.post('payments/stripe-cancel-subscription',
        {
          platform
        });
      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result.url;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const getUsersPaymentsHistory = createAsyncThunk(
  EUser.getPaymentHistory,
  async (_, thunkAPI) => {
    try {
      const data = await instance.get('payments/stripe-get-user-payments');
      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const removeUsersPaymentTax = createAsyncThunk(
  EUser.delTaxUser,
  async (id: string, thunkAPI) => {
    try {
      const data = await instance.delete('payments/stripe-delete-tax', {data: {tax_id: id}});
      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const getStripeDefaultPaymentMethod = createAsyncThunk(
  EUser.getStripeDefaultPayment,
  async (_, thunkAPI) => {
    try {
      const data = await instance.get('payments/get-stripe-default-payment-method');
      return (!data.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const setStripeDefaultPaymentMethod = createAsyncThunk(
  EUser.setStripeDefaultPayment,
  async (paymentMethod: ISetPaymentMethod, thunkAPI) => {
    try {
      const data = await instance.post('payments/stripe-attach-user-payment-method', {id: paymentMethod.id});
      return (!data.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const postDataToMarketing =  createAsyncThunk(
  EUser.sendActiveCampaignContact,
  async (payload: IMarketingData, thunkAPI) => {
    try {
      const data = await instance.post('send-active-campaign-tags', payload);
      return (!data.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
  }
}
);

const inviteNewUser = createAsyncThunk(
  EUser.invite,
  async (dataPost: ICreateOrganizationUser, thunkAPI) => {
    try {
      const data = await instance.post(
        'user/organization-user',
        {
          email: dataPost.email,
          roles: dataPost.roles
        }
      );
      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const editInvitedUser = createAsyncThunk(
  EUser.inviteUpd,
  async (dataPost: IUpdOrganizationUser, thunkAPI) => {
    try {
      const data = await instance.put(
        'user/organization-user',
        {
          targetUserSub: dataPost.targetUserSub,
          roles: dataPost.roles
        }
      );
      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const setOrgName = createAsyncThunk(
  EUser.setOrgName,
  async (name: string, thunkAPI) => {
    try {
      const data = await instance.post(
        'user/organization/name',
        {
          organization_name: name
        }
      );
      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const getInvitedUsersList = createAsyncThunk(
  EUser.userList,
  async (_, thunkAPI) => {
    try {
      const data = await instance.get('user/organization-users');

      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.organization_users;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

const deleteSubUser = createAsyncThunk(
  EUser.deleteUser,
  async (userSub: string, thunkAPI) => {
    try {
      const data = await instance.delete('user/organization-user', {data: {targetUserSub: userSub}});

      return (!data?.data || data.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: data.data.message }) :
        data.data.result;
    } catch (err) {
      const error = err as IError;
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export {
  getUser,
  setUserLoading,
  setUserTestData,
  setUserPro,
  login,
  loginGoogle,
  logout,
  userAction,
  editUser,
  updateUserAvatar,
  updateUserData,
  cancelAllUsersSubscriptions,
  cancelOneSubscription,
  unsubscribeMailProfile,
  getUserEmailPreferences,
  putUserEmailPreferences,
  getUsersPaymentsHistory,
  getStripeDefaultPaymentMethod,
  setStripeDefaultPaymentMethod,
  getUserProfileName,
  removeUsersPaymentTax,
  postDataToMarketing,
  inviteNewUser,
  editInvitedUser,
  setOrgName,
  getInvitedUsersList,
  deleteSubUser,
};
