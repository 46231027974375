import { StyledEngineProvider, Theme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect } from 'react';
import {useRoutes, useSearchParams} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import './App.scss';
import { cognitoSetup } from './cognito/cognitoTest';
import GlobalStyle from './GlobalStyle';
import useTheme from './hooks/useTheme';
import './i18n/i18nConfig';
import { ELocalStoreKeys } from './models/consts';
import routes from './routes';
import { getUser, logout } from './store/actions';
import { useAppDispatch } from './store/hooks';
import createTheme from './theme';
import {WebSocketProvider} from "./contexts/WebSocketContext";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {
  }
}


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE || '');

function App(): JSX.Element {
  const dispatch = useAppDispatch();
  const { theme } = useTheme();
  const content = useRoutes(routes);
  const [searchParams] = useSearchParams();
  const oktaDomain = searchParams.get('okta');

  useEffect(() => {
    if (oktaDomain && oktaDomain === 'dev-39661790') {
      cognitoSetup('5plkj2lhteo52c9jd7ivm41cqa');
    } else {
      cognitoSetup();
    }
    dispatch(getUser());
  }, []);

  useEffect(() => {
    window.addEventListener('storage', (e) => {
      if (!e.key || (e.key === ELocalStoreKeys.TOKEN && !e.newValue)) {
        dispatch(logout(false));
      }
    });
  }, []);

  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={createTheme(theme)}>
          <ThemeProvider theme={createTheme(theme)}>
            <WebSocketProvider>
            <GlobalStyle />
            <Elements stripe={stripePromise}>
              {content}
            </Elements>
            </WebSocketProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
