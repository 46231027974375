import { CircularProgress, Checkbox, InputAdornment } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FunctionComponent, useEffect, useState} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  errorBigIcon,
  iconNotifySuccess,
  iconNotifyWarningInfo,
  warningEmailBigIcon,
  gLogo, oktaLogoW,
  CheckboxInputEmpty, CheckboxInputChecked, toastSuccess
} from '../../../assets/icons/icons';
import ValidationErrorBlock from '../../../components/Blocks/ValidationErrorBlock/ValidationErrorBlock';
import IsVisibleButton from '../../../components/Buttons/IsVisibleButton/IsVisibleButton';
import { AuthWrapper as Wrapper, Button, FormControl, TextField } from '../../../components/StyledComponents';
import {
  EErrorText,
  EMAIL_REGEX,
  MAX_INPUT_VALUE_LENGTH,
  MAX_INPUT_VALUE_EMAIL_LENGTH,
} from '../../../models/consts';
import { IError, ILogin } from '../../../models/inner-models';
import {login, loginGoogle, resendUserRegistrationLink} from '../../../store/actions';
import { useAppDispatch } from '../../../store/hooks';
import { decoratePopUpMessage } from '../../../utils/popUpTextDecorator';
import withRedirectIfAuth from '../../../utils/withRedirectIfAuthed';
import '../style.scss';
import { Helmet } from 'react-helmet';
import {Auth, Hub} from "aws-amplify";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth";
import {iniFrame} from "../../../serviceWorker";
import {cognitoSetup} from "../../../cognito/cognitoTest";

const useStyles = makeStyles(() =>
  createStyles({
    spinner: {
      color: '#319CFF',
    },
    inputBlock: {
      width: '100%',
      marginTop: '4px',
    },
    textField: {
      width: '100%',
      height: '42px',
      '& input': {
        height: '42px',
        fontSize: '16px',
        boxSizing: 'border-box'
      }
    },
    passField: {
      width: '100%',
      '& > div': {
        paddingRight: '32px'
      },
      '& input': {
        height: '42px',
        fontSize: '16px',
        boxSizing: 'border-box'
      }
    },
    fullWidthButton: {
      width: '100%',
      marginTop: '4px',
      padding: '4px 24px',
      fontSize: '14px',
      fontWeight: '600',
    },
  }),
);

const Login: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { register, formState: { errors, isValid }, handleSubmit } = useForm<ILogin>({
    mode: 'onChange',
  });
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [visible, setPasswordVisibility] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [isGoogleAuth, setIsGoogleAuth] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  // Add this state to manage the Okta domain input
  const [isOkta, setIsOkta] = useState<boolean>(false);
  const [oktaDomain, setOktaDomain] = useState<string>('');
  const [oktaError, setOktaError] = useState<string>('');

  const isConfirmed = searchParams.get('isConfirmed');
  const handleClickShowPassword = () => () => {
    setPasswordVisibility(!visible);
  };

  // Function to handle the Okta domain input change
  const handleOktaDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!!oktaError) setOktaError('')
    setOktaDomain(event.target.value);
  };

  const getUser = async () => {
    setLoading(true)
    try {
      const token = await Auth.currentAuthenticatedUser()
      const isOktaToken = token?.signInUserSession?.idToken?.payload?.identities?.[0]?.providerName === 'okta'
      const payload = {
        token: token?.signInUserSession?.idToken?.jwtToken,
        isOkta: isOktaToken
      }
      await dispatch(loginGoogle(payload)).unwrap();

      if (localStorage.getItem('lastPage')) {
        window.location.assign(localStorage.getItem('lastPage') || '')
        localStorage.removeItem('lastPage')
      }
      setLoading(false)
      navigate('/');
    } catch(err) {
      setLoading(false)
      // console.log(err);
    }
  }

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signIn') {
        console.log('signin')
        return getUser();
      }
      if (payload.event === 'signOut') {
        console.log('signout')
      }
    });
    getUser();

    if (isConfirmed) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton:false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
        title: translate('notifications.titles.success'),
        text: translate('notifications.auth.verification_successful'),
      });
    }
  }, []);

  const onSubmit: SubmitHandler<ILogin> = async (data: ILogin) => {
    const onResendLink = async () => {
      try {
        await dispatch(resendUserRegistrationLink(data.email))

        await Swal.fire({
          title: translate('notifications.titles.success'),
          text: decoratePopUpMessage(translate('notifications.auth.link_resent')),
          imageUrl: iconNotifySuccess,
        });
      } catch (e) {
        const error = e as IError;

        return Swal.fire({
          imageUrl: errorBigIcon,
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
        });
      }
    }

    setProcessing(true);
    try {
      await dispatch(login(data)).unwrap();
      if (localStorage.getItem('lastPage')) {
        window.location.assign(localStorage.getItem('lastPage') || '')
        localStorage.removeItem('lastPage')
      }
      navigate('/');
    } catch (err) {
      const error = err as IError;
      console.log(error);

      if (!error.error?.includes(EErrorText.NOT_CONFIRMED)) {
        return Swal.fire({
          imageUrl: iconNotifyWarningInfo,
          customClass: {
            popup: 'err-login-container',
            title: "swal-login-page"
          },
          title: 'The email or password is incorrect. Please try again',
          html: 'Sign up and <a href="/auth/sign-up">start your 14-day free trial</a> or try again',
          confirmButtonText: 'Sign up',
          showCancelButton: true,
          cancelButtonText: 'Try again'
        }).then((res) => {
            if (res.isConfirmed) {
              navigate('/auth/sign-up')
            }
          }
        );
      }

      Swal.fire({
        title: translate('notifications.titles.warning'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: warningEmailBigIcon,
        confirmButtonText: translate('notifications.choices.resend_verification'),
        showCancelButton: true,
        preConfirm: () => {
          window.location.hash = 'resend_verification'
          return onResendLink();
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    } finally {
      setProcessing(false);
    }
  };

  const googleAuth = async()=> {
      if (!isGoogleAuth) {
        setIsGoogleAuth(true)
        Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google})
      }
  }

  const oktaAuth = ()=> {
      if (!isOkta) {
        setIsOkta(true)
      } else {
        handleOktaLogin()
      }
  }

  const handleOktaLogin = async () => {
    if (oktaDomain === 'dev-39661790') {
      // Call the Okta sign-in method for your company domain
      await handleOktaSignIn('5plkj2lhteo52c9jd7ivm41cqa');
    } else {
      // Handle other domains or show an error message
      setOktaError('Invalid Okta domain');
    }
  };

  const handleOktaSignIn = async (userPoolWebClientId: string) => {
    try {

      Auth.configure({
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        // userPoolId: 'us-west-2_2u2JkpeZ1',
        userPoolWebClientId: userPoolWebClientId,
        oauth: {
          region: 'us-west-2',
          domain: process.env.REACT_APP_COGNITO_DOMAIN,
          scope: [
            'email',
            'openid',
            'profile',
          ],
          redirectSignIn: `${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN}/auth/login?okta=dev-39661790`,
          redirectSignOut: `${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN}/auth/login`,
          responseType: 'token',
        },
      });
      await Auth.federatedSignIn({provider: 'okta'});

    } catch (error) {
      console.log("Error signing in:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>BackupLABS Login Page</title>
        <meta name="title" content="BackupLABS Login Page" />
        <meta name="description" content="Log into the BackupLABS SaaS app backup platform." />
      </Helmet>
      <Wrapper>
        {loading ?
          <div className="spinner-wrapper">
            <CircularProgress className={classes.spinner} />
          </div> :
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="auth-title">
              {translate('common.default_pages.sign_in')}
            </div>
            {!isOkta && (
              <div className="links-block center">
                {translate('forms.sign_in.dont_have_acc')}
                <Link to="/auth/sign-up" className="link ml-4">
                  {translate('forms.sign_in.trial')}
                </Link>
              </div>
            )}
            {!isOkta && (
              <div>
                <FormControl className="form-row">
                  <div className="label-block">{translate('forms.common.email')}</div>
                  <div className={classes.inputBlock}>
                    <TextField
                      required
                      placeholder={translate('forms.common.email')}
                      className={classes.textField}
                      id="email"
                      type="email"
                      inputProps={{ maxLength: MAX_INPUT_VALUE_EMAIL_LENGTH }}
                      {...register('email', {
                        required: translate('forms.common.required') as string,
                        pattern: {
                          value: EMAIL_REGEX,
                          message: translate('forms.common.invalid_email'),
                        },
                      })}
                      error={!!errors.email}
                    />
                    {errors.email &&
                    <ValidationErrorBlock errorMessage={errors.email.message as string} />
                    }
                  </div>
                </FormControl>
              </div>
            )}
            {!isOkta && (
              <div>
                <FormControl className="form-row">
                  <div className="label-block">{translate('forms.common.password')}</div>
                  <div className={classes.inputBlock}>
                    <TextField
                      className={classes.passField}
                      placeholder={translate('forms.common.password')}
                      required
                      id="password"
                      type={visible ? 'text' : 'password'}
                      {...register('password', {
                        required: translate('forms.common.required') as string,
                      })}
                      error={!!errors.password}
                      inputProps={{ maxLength: MAX_INPUT_VALUE_LENGTH }}
                      InputProps={{
                        endAdornment:
                          <InputAdornment position="end">
                            <IsVisibleButton
                              isPrimary
                              onClick={handleClickShowPassword()}
                              isNowVisible={visible}
                            />
                          </InputAdornment>,
                      }}
                    />
                    {errors.password &&
                    <ValidationErrorBlock errorMessage={errors.password.message as string} />
                    }
                  </div>
                </FormControl>
                <div className="links-block remember">
                  <Checkbox
                    icon={<CheckboxInputEmpty/>}
                    checkedIcon={<CheckboxInputChecked />}
                  /> Remember me
                </div>
                <div className="links-block m-30 forget-link">
                  <Link to="/auth/password-recovery" className="link">
                    {translate('forms.sign_in.forgot_password')}
                  </Link>
                </div>
              </div>
            )}
            {isOkta && (
              <div className='okta-input-domain'>
                <FormControl className="form-row">
                  <div className="label-block full-width">Your Okta Company Domain</div>
                  <div className={classes.inputBlock}>
                    <TextField
                      required
                      placeholder='Okta Company Domain'
                      className={classes.textField}
                      id="email"
                      type="text"
                      value={oktaDomain}
                      onChange={handleOktaDomainChange}
                      inputProps={{ maxLength: 25 }}
                      error={!!oktaError}
                    />
                    {!!oktaError &&
                      <ValidationErrorBlock errorMessage={oktaError} />
                    }
                  </div>
                </FormControl>
              </div>
            )}
            <div className="action-row">
              {!isOkta && (
                <Button className="primary-button w-100" type="submit"
                      disabled={!isValid || isProcessing || isGoogleAuth}>
                  {isProcessing &&
                      <div className="small-spinner-wrapper">
                        <CircularProgress color="inherit" style={{width: '20px', height: '20px', marginTop: '15px'}}/>
                      </div>
                  }
                  {isProcessing
                      ? translate('notifications.choices.processing')
                      : translate('common.default_pages.sign_in')
                  }
                </Button>
              )}
              {!iniFrame() && !isOkta && (
                  <div className='btn-split'>or</div>
              )}

              {!iniFrame() && !isOkta && (
                <div className={`google-btn ${isGoogleAuth && 'disabled'}`}
                     onClick={googleAuth}
                >
                  <div className="google-icon-wrapper">
                    <img className="google-icon-svg"
                         src={gLogo} loading="lazy"/>
                  </div>
                  <p className="btn-text"><b>Sign in with Google</b></p>
                </div>
              )}
              {!iniFrame() && (
                <div className={`google-btn ${isGoogleAuth && 'disabled'} okta-btn`}
                     onClick={oktaAuth}
                >
                  <div className="okta-icon-wrapper">
                    <img className="okta-icon-svg"
                         src={oktaLogoW} loading="lazy"/>
                  </div>
                  <p className="btn-text"><b>Sign in with Okta</b></p>
                </div>
              )}
              {!iniFrame() && isOkta && (
                <Button className={classes.fullWidthButton} onClick={() => setIsOkta(false)} variant="outlined"
                        color="primary" mt={4}>
                  {translate('notifications.choices.cancel')}
                </Button>
              )}
            </div>
          </form>
        }
      </Wrapper>
    </>
  );
};

export default withRedirectIfAuth()(Login);
